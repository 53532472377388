function Core(selector, container) {
	return typeof selector === "string"? (container || document).querySelector(selector) : selector || null;
}

Core.setup = function() {
	this.ready(() => {
		this.breakpoints = {
			smallMax: this.getVariable("breakpoints-small-max"),
			medium: this.getVariable("breakpoints-medium"),
			mediumMax: this.getVariable("breakpoints-medium-max"),
			large: this.getVariable("breakpoints-large"),
			largeMax: this.getVariable("breakpoints-large-max"),
			xlarge: this.getVariable("breakpoints-xlarge")
		}
	});
}

Core.$$ = function(selector, container) {
	return typeof selector === "string"? (container || document).querySelectorAll(selector) : selector || null;
}

/*Core.array = function(selector, container) {
	return [].slice.call(Core.$$(selector, container));
}*/

/*Core.$$ = function(selector, container) {
	return [].slice.call((container || document).querySelectorAll(selector));
	// return [].slice.call((container === undefined ? document : container).querySelectorAll(selector));
}*/

Core.ready = function(fn) {
	if(document.readyState !== "loading") {
		fn();
	}
	else {
		document.addEventListener("DOMContentLoaded", fn);
	}
}

Core.initVariable = function(name) {
	var el = document.querySelector("head").appendChild(document.createElement("meta"));
	el.classList.add(name);
}

Core.getVariable = function(name) {
    if(document.querySelector("meta." + name) == null) {
        this.initVariable(name);
    }

    var getCompStyle = getComputedStyle(document.querySelector("meta." + name));

    if(getCompStyle && getCompStyle["font-family"]) {
        return getCompStyle["font-family"].replace(/["']/g, "");
    }
}

Core.scrollTo = function(el, options) {
	const offset = !options || options.offset === undefined ? 0 : options.offset;
	const onScreen = !options ||  options.onScreen === undefined ? false : options.onScreen;
	const top = el.getBoundingClientRect().top;

	if(!onScreen || (top < offset || top > window.innerHeight)) {
		let pos = top + Core.scrollTop() - offset;
		
		window.scroll({top: pos, left: 0, behavior: "smooth"});	
	}
}

Core.scrollTop = function() {
	return document.documentElement.scrollTop || document.body.scrollTop;
}

Core.index = function(el) {
	var index = 0;

	while(el = el.previousElementSibling) {
		index++;
    }
	
	return index;
}

Core.trigger = function(el, name) {
	var event = document.createEvent("HTMLEvents");
	event.initEvent(name, true, false);
	el.dispatchEvent(event);
};

Core.animate = function(el, duration, props) {
	var old = el.style.transition;
	var oldWebkit = el.style.webkitTransition;
	var transition = "";
	var webkitTransition = "";
	var count = 0;
	var counter = 0;
	var easing = props.ease || "linear";
	var delay = props.delay || 0;
	var complete = props.complete || null;

	for(var key in props) {
		if(key == "ease" || key == "delay" || key == "complete") {
			delete props[key];
			continue;
		}

		var str = key.replace(/([a-z])([A-Z])/g, "$1-$2");
		var tmp = str.toLowerCase() + " " + duration + "s " + easing + " " + delay + "s, ";

		transition += tmp;
		count++;

		if(str.slice(0, 9) == "transform") {
			str = "-webkit-" + str;
			webkitTransition += str.toLowerCase() + " " + duration + "s " + easing + " " + delay + "s, ";
		}
		else {
			webkitTransition += tmp;
		}
	}

	el.style.webkitTransition = webkitTransition.substring(0, webkitTransition.length - 2);
	el.style.transition = transition.substring(0, transition.length - 2);

	for(var key in props) {
		var str = key.replace(/([a-z])([A-Z])/g, "$1-$2");

		el.style[key] = props[key];

		if(str.slice(0, 9) == "transform") {
			el.style["-webkit-" + key] = props[key];
		}
	}

	el.addEventListener("transitionend", end);
	el.addEventListener("webkitTransitionEnd", end);

	var t = setTimeout(end2, duration * 1000 + delay * 1000 + 50);
	
	function end(e) {
		if(++counter < count || e.target != e.currentTarget) {
			return false;
		}

		clearTimeout(t);
		counter = 0;
		end2();
	}

	function end2() {
		el.removeEventListener("transitionend", end);
		el.removeEventListener("webkitTransitionEnd", end);
		el.style.transition = old;
		el.style.webkitTransition = oldWebkit;

		if(complete) {
			complete.call();
		}
	}
}

Core.fetch = function(url, props) {
	if(!url) {
		return false;
	}

	var data = props.data || "";
	var cb = props.callback || function() {};
	var ecb = props.errorCallback || function() {};
	var method = props.method || "GET";
	var headers = props.headers || {};
	var dataType = props.dataType || "text";

	var xhr = new XMLHttpRequest();

	if(!xhr) {
		return false;
	}

	xhr.onreadystatechange = function() {
		if(xhr.readyState === XMLHttpRequest.DONE)
			if(xhr.status === 200) {
				var response = xhr.responseText;

				if(dataType == "json") {
					try {
						cb(JSON.parse(response));
					}
					catch(err) {
						console.error(err);
					}
				}
				else {
					cb(response);
				}
			} else {
				ecb(xhr.status, xhr.responseText);
			}
	};

	xhr.open(method, url);
	
	if(!headers["Content-Type"]) {
		xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
	}

	for(var property in headers) {
		if(headers.hasOwnProperty(property)) {
			xhr.setRequestHeader(property, headers[property]);
		}
	}

	xhr.send(data);
}

Core.slideToggle = function(el) {
	var height = el.offsetHeight;
	// var padding = parseInt(getComputedStyle(el)["padding-top"], 10) + parseInt(getComputedStyle(el)["padding-bottom"], 10);
	
	if(height > 0) {
		el.style.height = height + "px";

		void el.offsetWidth;

		el.style.height = "0";
	}
	else {
		el.style.height = "auto";
		height = el.offsetHeight;
		el.style.height = "0";

		void el.offsetWidth;

		el.style.height = height + "px";
		el.addEventListener("transitionend", onEnd);
		el.addEventListener("webkitTransitionEnd", onEnd);
	}

	function onEnd() {
		if(el.offsetHeight > 0) {
			el.style.height = "auto";
		}
		
		el.removeEventListener("transitionend", onEnd);
		el.removeEventListener("webkitTransitionEnd", onEnd);
	}
}

Core.createVideo = function(el) {
	const source = el.querySelector("source");

	source.setAttribute("src", source.getAttribute("data-src"));
	source.setAttribute("data-src", "");
	el.load();
}

// Initialize Core
Core.setup();

// Add global shortcuts
var $$ = Core.$$;

if(!typeof $ != "undefined") {
	var $ = Core;
}

class Component {

	constructor(el) {
		this.el = el;
	}

	static setup(arr, ref, selector, settings) {
		arr = arr || document.querySelectorAll(selector);

		if(arr.length == 0) {
			return false;
		}

		const items = [];

		arr.forEach(i => {
			items.push(new ref(i, settings));
		});

		return items;
	}

	init(settings) {
		if(settings) {
			for(var i in settings) {
				if(settings.hasOwnProperty(i)) {
					this.settings[i] = settings[i];
				}
			}
		}
	}

	addEventListener(name, fn) {
		this.el.addEventListener(name, e => {
			fn.call(this, e);
		});
	}

	dispatchEvent(name, props) {
		this.el.dispatchEvent(new CustomEvent(name, props));
	}
}
class Fader {

	constructor() {
		this.settings = {
			repeat: false
		}

		this.assets = [];
		this.observers = [];

		document.querySelectorAll("[class*='fade-']").forEach(i => {
			const delay = i.getAttribute("data-delay");
			const scroll = i.getAttribute("data-scroll") ? true : false;
			const obj = {};

			obj.el = i;
			obj.scroll = scroll;
			obj.offset = i.getAttribute("data-offset") ? parseInt(i.getAttribute("data-offset"), 10) : 0;

			if(delay) {
				obj.el.style.transitionDelay = i.getAttribute("data-delay") + "s";
			}

			this.assets.push(obj);
			
			// IntersectionObserver
			const options = {
				rootMargin: "0px 0px " + obj.offset + "px 0px"
			}

			const observer = new IntersectionObserver(entries => {
				entries.forEach(item => {
					if(item.isIntersecting) {
						// this.update(item.target);
						item.target.classList.add("fade-in");

						if(!this.settings.repeat) {
							observer.unobserve(item.target);
						}
					}
					else {
						if(this.settings.repeat) {
							item.target.classList.remove("fade-in");
						}
					}
				});
			}, options);

			observer.observe(i);
			this.observers.push(observer);
		});
	}

	/*

	update2(scroll) {
		this.assets.forEach(i => {
			if(i.scroll && !scroll) return;

			const el = i.el;
			const top = el.getBoundingClientRect().top;
			
			if(top + i.offset < this.windowHeight) {
				el.classList.add("fade-in");

				if(!this.settings.repeat) {
					const index = this.assets.indexOf(i);
					this.assets.splice(index, 1);
				}
			}
			else {
				if(this.settings.repeat) {
					el.classList.remove("fade-in");
				}
			}
		});

		if(this.assets.length == 0) {
			this.destroy();
		}
	}*/

	update(el) {
		el.classList.add("fade-in");
	}

	cancel() {
		this.destroy();
		
		this.assets.forEach(i => {
			i.el.classList.add("fade-in");
		});
	}

	destroy() {
		let i = 0;

		this.assets.forEach(item => {
			this.observers[i++].unobserve(item.el);
		});
	}
}
class Overlay extends Component {

	constructor(el, settings) {
		super(el);

		// Settings
		this.settings = {
			history: false
		}

		this.init(settings);

		this.buttons = [];
		this.backHandler = this.back.bind(this);
		this.videoLoaded = false;

		document.querySelectorAll("[data-overlay-button='" + this.el.getAttribute("id") + "']").forEach(i => {
			this.buttons.push(i);
		});

		this.build();
	}

	static setup(arr) {
		return super.setup(arr, Overlay, "[data-overlay]");
	}

	build() {
		// const background = document.createElement("div");
		// background.classList.add("overlay-background");
		// this.el.appendChild(background);

		// background.addEventListener("click", (e) => {
		// 	this.close(true);
		// });

		this.el.addEventListener("click", (e) => {
			if(e.target == e.currentTarget) {
				this.close(true);	
			}
		});

		const close = this.el.querySelector(".overlay-close");

		close.addEventListener("click", (e) => {
			this.close(true);
		});

		this.buttons.forEach(i => {
			i.setAttribute("data-controls", this.el.getAttribute("id"));
			i.addEventListener("click", (e) => {
				e.preventDefault();

				this.callingButton = i;
				this.open();
			});
		});

		// Esc key listener
		this.el.addEventListener("keydown", e => {
			let key = e.which || e.keyCode;

			if(key == 27) {
				if(this.el.classList.contains("m-active")) {
					this.close(true, true);
				}
			}
		});
	}

	open(push) {
		if(this.settings.history) {
			if(push !== false) {
				window.history.pushState({ url: this.el.getAttribute("data-url") }, "", this.el.getAttribute("data-url"));
			}

			window.addEventListener("popstate", this.backHandler);	
		}

		document.documentElement.classList.add("m-overlay-open");
		this.el.classList.add("m-active");
		this.el.scrollTop = 0;
		this.el.setAttribute("aria-hidden", false);
		this.el.focus();

		this.dispatchEvent("open");

		// Video overlay
		if(this.el.getAttribute("data-overlay-type") === "video" && !this.videoLoaded) {
			var iframe = this.el.querySelector("iframe");
			iframe.addEventListener("load", e => {
				this.el.querySelector(".c-loader").classList.remove("m-active");

				if(this.player) {
					this.player.play();
				}
			});

			iframe.src = "https://player.vimeo.com/video/" + this.el.getAttribute("data-video-id");

			this.player = new Vimeo.Player(iframe);

			this.videoLoaded = true;
		}
	}

	back() {
		this.close(false)
	}

	close(back, keyboard) {
		if(this.settings.history) {
			if(back) {
				window.history.back();
			}
		
			window.removeEventListener("popstate", this.backHandler);
		}

		document.documentElement.classList.remove("m-overlay-open");
		this.el.classList.remove("m-active");
		this.el.setAttribute("aria-hidden", true);
		this.el.blur();

		if(keyboard) {
			this.callingButton.focus();
		}

		this.dispatchEvent("close");

		if(this.player) {
			this.player.pause();
			this.player.setCurrentTime(0);
		}
	}
}
class Validator {

	constructor(el, options) {
		this.options = options || {};
		this.el = el;
		this.errors = [];

		// const submitEvent = options.submitEvent === undefined ? true : options.submitEvent;
		
		// if(submitEvent) {
		// 	this.el.addEventListener("submit", e => {
		// 		this.validate(e);
		// 	}, false);
		// }
	}

	validate(e) {
		this.errors.length = 0;

		this.el.querySelectorAll("[required]").forEach(i => {
			// Skip fields that are not visible
			if((i.offsetParent !== null) == false) {
				return;
			}

			this.validateField(i);
		});

		if(this.errors.length > 0) {
			if(e) {
				e.preventDefault();
			}

			if(typeof this.options.callback === "function") {
				this.options.callback(this.errors);
			}

			return false;
		}

		return true;
	}

	validateField(i) {
		this.error = false;

		// Radio and checkbox
		if(i.getAttribute("type") == "radio" || i.getAttribute("type") == "checkbox") {
			let error = true;

			this.el.querySelectorAll("input[name=" + i.getAttribute("name") + "]").forEach(function(i) {
				if(i.checked) {
					error = false;
				}
			});

			if(error) {
				this.addError(i, "required");
			}
		}
		// Default
		else if(i.value == "") {
			this.addError(i, "required");
		}

		// Select
		else if(i.tagName == "SELECT") {
			if(i.value == 0) {
				this.addError(i, "required");
			}
		}

		// Email
		if(i.hasAttribute("data-required-email")) {
			if(!/\S+@\S+\.\S+/.test(i.value)) {
				this.addError(i, "data-required-email");
			}
		}

		// Number
		if(i.hasAttribute("data-required-number")) {
			if(isNaN(i.value)) {
				this.addError(i, "data-required-number");
			}
		}

		return !this.error;
	}

	addError(target, id) {
		// Reset error
		this.error = true;

		// Create error object
		const obj = {
			name: target.getAttribute("name"),
			error: id,
			element: target,
			message: target.getAttribute("data-message")
		};

		this.errors.push(obj);
	}
}
class Header {

	constructor(el) {
		this.el = el;
		this.oldScroll = 0;
		this.firstLoad = true;
		this.threshold = 0;

		window.addEventListener("scroll", e => {
			this.update();
		});

		this.update();
	}

	update() {
		const top = Core.scrollTop();

		// Small (header is visible but smaller when scrolling)
		if(top > this.threshold) {
			this.el.classList.add("m-small");
		}
		else {
			this.el.classList.remove("m-small");
		}

		// Hidden (header is hidden when scrolling down)
		if(Math.abs(top - this.oldScroll) > 20) {
			if(this.oldScroll > top) {
				this.el.classList.remove("m-hidden");
			}
			else {
				if(top > this.threshold && !this.firstLoad) {
					this.el.classList.add("m-hidden");
				}
			}

			this.oldScroll = top;
		}

		this.firstLoad = false;
	}
}
class Navigation {

	constructor(el) {
		this.el = el;
		this.parentIsLink = true;
		this.showActiveMenu = true;
		this.toggleButton = document.querySelector("#nav-button");
		this.closeButton = document.querySelector("#nav-close");
		
		this.build();
	}

	build() {
		this.toggleButton.addEventListener("click", e => {
			if(!document.documentElement.classList.contains("m-nav-open")) {
				this.open();
			}
			else {
				this.close();
			}
		});

		this.closeButton.addEventListener("click", e => {
			this.close();
		});

		// Esc key
		this.el.addEventListener("keydown", e => {
			let key = e.which || e.keyCode;

			if(key == 27) {
				this.close();
			}
		});

		// Remove transition when animation complete
		this.el.addEventListener("transitionend", e => {
			document.documentElement.classList.remove("m-nav-open-transition");
		});

		this.el.addEventListener("webkitTransitionEnd", e => {
			document.documentElement.classList.remove("m-nav-open-transition");
		});
	}

	open() {
		document.documentElement.classList.add("m-nav-open");
		document.documentElement.classList.add("m-nav-open-transition");
		this.el.scrollTo(0,0);
		// this.el.querySelector("nav").focus();
		this.toggleButton.setAttribute("aria-expanded", true);
	}

	close() {
		document.documentElement.classList.remove("m-nav-open");
		document.documentElement.classList.add("m-nav-open-transition");
		this.toggleButton.setAttribute("aria-expanded", false);
	}
}
class Parallax {

	constructor(el, reverse) {
		this.el = el;
		// this.assets = assets;
		this.y = 0;
		this.current = -1;
		this.reverse = reverse;

		this.build();
	}

	build() {
		this.calculatePosition();

		window.addEventListener("scroll", e => {
			this.calculatePosition();
		});

		window.addEventListener("resize", e => {
			if(!window.matchMedia("(min-width: " + Core.breakpoints.large + ")").matches) {
				this.reset();
			}
		});

		this.update();
	}

	calculatePosition() {
		var top = Core.scrollTop() - this.el.getBoundingClientRect().top;
		this.y = -Math.floor(top / 30);

		if(this.reverse) {
			this.y *= -1;
		}
	}

	update() {
		if(this.y != this.current) {
			if(window.matchMedia("(min-width: " + Core.breakpoints.large + ")").matches) {
				this.el.style.transform = "translateY(" + this.y + "px)";
				this.current = this.y;
			}
		}
		
		requestAnimationFrame(this.update.bind(this));
	}

	reset() {
		this.el.style.transform = "none";
		this.current = 0;
	}
}
class ProductSpotlight {

	constructor(el) {
		this.el = el;

		this.build();
	}

	build() {
		const text = this.el.querySelector(".text");

		this.el.querySelectorAll("button").forEach(item => {
			item.addEventListener("click", e => {
				text.innerHTML = "<p>" + item.getAttribute("data-description") + "</p>";

				this.el.querySelectorAll("button").forEach(item2 => {
					item2.classList.remove("m-active");
				});

				item.classList.add("m-active");

				if(!text.classList.contains("m-active")) {
					text.classList.add("m-active");
				}
			});
		});
	}
}
class StatisticsMap {

	constructor(el) {
		this.el = el;

		this.build();
	}

	build() {
		const observer = new IntersectionObserver(entries => {
			entries.forEach(item => {
				if(item.isIntersecting) {
					// console.log('in the view');
					observer.unobserve(item.target);
					item.target.querySelector("svg").classList.add("active");

					item.target.querySelectorAll("p").forEach(text => {
						text.classList.add("m-active");
					});
				} else {
					// console.log('out of view');
				}
			});
		});

		observer.observe(this.el);
	}
}
class App {

	constructor(queue) {
		this.isReady = false;
		this.readyQueue = queue;
		this.mobile = false;
	}

	init() {
		Core.ready(() => {
			this.isReady = true;
			this.start();
			this.processQueue();
		});
	}

	ready(fn) {
		if(this.isReady) {
			fn();
		} else {
			this.readyQueue.push(fn);
		}
	}

	processQueue() {
		while(this.readyQueue.length) {
			this.readyQueue.shift()();
		}
	}

	start() {
		// Check for mobile
		if(isMobile.phone || isMobile.tablet) {
			this.mobile = true;
			document.documentElement.classList.add("m-mobile-device");
		}

		// Focus visible
		document.body.addEventListener("keyup", e => {
			var key = e.which || e.keyCode;

			if(key === 9) {
				document.documentElement.classList.add('m-focus-visible');
			}
		});

		document.body.addEventListener("click", e => {
			document.documentElement.classList.remove('m-focus-visible');
		});

		// Header
		new Header(document.querySelector("#header"));

		// Navigation
		new Navigation(document.querySelector("#navigation"));

		// Hero
		// new Hero(document.querySelector("#hero"));

		// Overlay
		Overlay.setup();

		// Fader
		new Fader();

		// Parallax
		document.querySelectorAll(".c-reference-item img").forEach((item, index) => {
			new Parallax(item, index % 2 == 0 ? true : false);
		});

		document.querySelectorAll(".c-responsibility img").forEach((item, index) => {
			new Parallax(item, true);
		});

		document.querySelectorAll(".c-services .text").forEach((item, index) => {
			new Parallax(item, true);
		});
	}
}

app = new App(app.queue);
app.init();